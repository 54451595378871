








































import { Vue, Component, ModelSync } from "vue-property-decorator";
import { CO2FilterFormInterface, ComboBoxItem } from "@/interfaces";

@Component
export default class CO2FilterForm extends Vue {
    @ModelSync("CO2FilterForm", "change", { type: Object })
    readonly formValues!: CO2FilterFormInterface;

    demandUnits: ComboBoxItem[] = [
        { text: this.$t("CO2FilterForm.agriculture"), value: "agriculture" },
        { text: this.$t("CO2FilterForm.urban"), value: "urban" },
        { text: this.$t("CO2FilterForm.industry"), value: "industry" },
        { text: this.$t("CO2FilterForm.golf"), value: "golf" },
        { text: this.$t("CO2FilterForm.wetland"), value: "wetland" }
    ];

    periods: ComboBoxItem[] = [
        {
            text: this.$t("CO2FilterForm.monthlyMonthly"),
            value: "monthlyMonthly"
        },
        {
            text: this.$t("CO2FilterForm.monthlyDaily"),
            value: "monthlyDaily"
        },
        { text: this.$t("CO2FilterForm.daily"), value: "daily" }
    ];

    onCO2FilterUpdated() {
        this.$emit("on-co2-filter-updated", {
            layer: this.formValues.layer,
            period: this.formValues.period
        });
    }
}
